import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { H2, P } from '../components/typography';
import Container from '../components/container';
import Section from '../components/section';
import { fluidImageType } from '../helper/prop-types';

const TermsPage = ({ data }) => (
    <Layout
        title="Teilnahmebedingungen für das Wiesn-Gewinnspiel 2019"
        description="Hier findest du die Teilnahmebedingungen für Die Original Lechtaler-Gewinnspiele. Aktuell kannst du einen Wiesn-Abend 2019 gewinnen."
    >
        <Hero image={data.file.childImageSharp.fluid} />
        <Section>
            <Container maxWidth="small">
                <H2>Teilnahmebedingungen</H2>
                <P>
                    Veranstalter des Gewinnspiels ist die Landsberger Wurstspezialitäten GmbH & Co.
                    KG, Justus-von-Liebig-Straße 48, 86899 Landsberg am Lech.
                </P>

                <P>
                    Teilnehmen dürfen alle Personen ab dem 18. Lebensjahr, ausgenommen Mitarbeiter
                    der Landsberger Wurstspezialitäten GmbH & Co. KG, deren Angehörige (Verwandte 1.
                    und 2. Grades) und Haushaltsmitglieder, sowie Personen mit Wohnsitz im Ausland.
                    Die Teilnahme über vollautomatische Gewinnspieleintragung ist ausgeschlossen.
                    Die Teilnahme an der Verlosung ist kostenlos und unabhängig von dem Erwerb von
                    Waren und Dienstleistungen möglich.
                </P>

                <P>
                    Die Teilnahme für das Gewinnspiel endet am 20.09.2019 um 22 Uhr (MEZ). Sie haben
                    die Möglichkeit, sich unter www.die-original-lechtaler.de für die Teilnahme an
                    der Verlosung zu registrieren. Hierfür müssen die abgefragten Daten
                    wahrheitsgemäß angegeben und die Teilnahmebedingungen akzeptiert werden. Die
                    Gewinner werden per Zufallsverfahren ermittelt.
                </P>

                <P>
                    Folgende Preise werden vergeben: Einen exklusiven Abend auf dem Oktoberfest 2019
                    für zwei Personen in einem der großen Festzelte. Inklusive Bezuschussung der
                    Fahrtkosten nach München in Höhe von max. 100,-€.
                </P>

                <P>
                    Die Gewinner werden zeitnah unter Verwendung der bei der Teilnahme gemachten
                    Angaben kontaktiert. Wenn die Gewinner den Erhalt der Gewinnbenachrichtigung
                    nicht unverzüglich bestätigen und die vom Veranstalter für die Gewinnabwicklung
                    benötigten Informationen nicht rechtzeitig zur Verfügung stellen, geht der
                    Gewinnanspruch verloren. Die im Rahmen des Gewinnspiels erhobenen Angaben werden
                    ausschließlich zur Bearbeitung im Rahmen der Teilnahme an dem Gewinnspiel
                    verwendet(Ermittlung und Benachrichtigung des Gewinners, Prüfung der Einhaltung
                    der Teilnahmebedingungen und Überlassung des Gewinns).
                </P>

                <P>
                    Die Gewinner werden zeitnah unter Verwendung der bei der Teilnahme gemachten
                    Angaben kontaktiert. Wenn die Gewinner den Erhalt der Gewinnbenachrichtigung
                    nicht unverzüglich bestätigen und die vom Veranstalter für die Gewinnabwicklung
                    benötigten Informationen nicht rechtzeitig zur Verfügung stellen, geht der
                    Gewinnanspruch verloren. Die im Rahmen des Gewinnspiels erhobenen Angaben werden
                    ausschließlich zur Bearbeitung im Rahmen der Teilnahme an dem Gewinnspiel
                    verwendet (Ermittlung und Benachrichtigung des Gewinners, Prüfung der Einhaltung
                    der Teilnahmebedingungen und Überlassung des Gewinns). Eine Weitergabe an Dritte
                    erfolgt nicht, sowie dies nicht zur Abwicklung des Gewinnspiels und Prüfung des
                    Gewinnfalls erforderlich ist. Der Teilnehmer ist im Gewinnfall damit
                    einverstanden, dass sein Name (und sein Bild) vom Veranstalter bzw. von dessen
                    verbundenen Unternehmen unentgeltlich veröffentlicht und zu Werbezwecken über
                    das Gewinnspiel verwendet wird. Eine Einwilligung des Gewinners in
                    Werbesendungen an ihn ist damit nicht verbunden. Diese Einwilligungen sind
                    jederzeit für die Zukunft widerruflich. Der Widerruf hat keine Auswirkungen auf
                    den Gewinnfall. Eine Barauszahlung oder ein Tausch des Gewinns ist nicht
                    möglich. Der Gewinn ist nicht übertragbar. Soweit eine Steuerpflicht auf den
                    Gewinn besteht, verpflichtet sich der Teilnehmer, solche Steuern ordnungsgemäß
                    abzuführen.
                </P>
                <P>
                    Mit Absendung / Übergabe des Gewinns erlöschen die Verpflichtungen der
                    Landsberger Wurstspezialitäten GmbH & Co. KG aus dem Gewinnspiel. Der
                    Veranstalter behält sich vor, jederzeit die Teilnahmebedingungen zu ändern.
                    Weiterhin behält sich der Veranstalter das Recht vor, das Gewinnspiel jederzeit
                    aus wichtigem Grund ohne Vorankündigung zu beenden oder zu unterbrechen und
                    einzelne Teilnehmer aus wichtigem Grund auszuschließen. Weiterhin werden
                    Personen, die sich unerlaubter Hilfsmittel bedienen oder sich durch anderweitige
                    Manipulation Vorteile verschaffen oder dies versuchen, vom Gewinnspiel
                    ausgeschlossen. Wer unwahre Personenangaben macht, kann vom Gewinnspiel
                    ausgeschlossen werden oder den Gewinn aberkannt bekommen.
                </P>
                <P>
                    Die Landsberger Wurstspezialitäten GmbH & Co. KG übernimmt keine Haftung im
                    Hinblick auf das Gewinnspiel oder die Gewinne. Der Rechtsweg ist ausgeschlossen.
                    Es gilt deutsches Recht.
                </P>
            </Container>
        </Section>
    </Layout>
);

TermsPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
    }).isRequired,
};

export default TermsPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-wiesn.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
